/* eslint camelcase: 0 */


import experienceLogo from '../../../img/experience.png';
import googleLogo from '../../../svg/google.svg';
import zillowLogo from '../../../svg/zillow.svg';
import starGold from '../../../svg/star-gold.svg';
import starGray from '../../../svg/star-gray.svg';

const vendorLogo = {
  experience: experienceLogo,
  zillow: zillowLogo,
  google: googleLogo,
};

const starsHtml = (rate) => {
  const MAX_RATE = 5;
  const rateArray = Array(MAX_RATE).fill();
  const star = (current) => (current <= rate ? starGold : starGray);

  return rateArray.map((_, idx) => `<div class="sp__review-star">
              <div class="svg w-embed">
                <img src="${star(idx + 1)}" alt="star">
              </div>
            </div>`)
    .join('');
};

export const htmlTemplateReviewPage = (review, state) => {
  const { first_name, last_name, content, total, rating } = review;
  const firstName = first_name;
  const lastName = last_name ?? ' ';
  const name = `${firstName} ${lastName[0] ?? ''}.`;
  const draftState = state;

  if (!draftState.total) {
    draftState.total = total;
  }

  return `
  <div class="testimonials_slide w-slide">
                <div class="testimonial_slide_inner sp__testimonial_slide_inner">
                  <div class="testimonial__main-block">
                    <div class="sp__testimonial__name-row">
                      <div class="h3__title-text">${name}</div>
                      <div class="sp__review_stars">
                        ${starsHtml(rating.toFixed())}
                      </div>
                    </div>
                    <div class="text-m">${content}</div>
                  </div>
                </div>
              </div>
`;
};
