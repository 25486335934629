import { fetchAll } from '../../api';
import { getContentElement, buildReviewsUrl, webflowRestart } from '../../utils';

import { renderReviews } from './render';

const initReviews = () => {
  const elements = {
    sectionReviews: document.querySelector('[data-reviews="section"]'),
    wrapperReviews: document.querySelector('[data-reviews="wrapper"]'),
    wrapperReviewsPage: document.querySelector('[data-reviews="wrapper-page"]'),
    links: {
      experience: document.querySelector('[data-reviews="experience-link"]'),
    },
    company: document.querySelector('[data-reviews="company"]'),
    agent_email: document.querySelector('[data-reviews="agent_email"]'),
    email: document.querySelector('[data-reviews="email"]'),
    placeId: document.querySelector('[data-reviews="place_id"]'),
    total: document.querySelector('[data-reviews="total"]'),
  };
  const configRequest = {
    base: 'https://services.wowmi.us/api/web/api/v1/reviews',
    args: {
      rate_min: 4,
      agent_email: getContentElement(elements.agent_email),
    },
  };
  const state = {
    total: null,
  };

  const { sectionReviews } = elements;
  if (!sectionReviews) {
    return false;
  }

  // EXPERIENCE CONFIG
  const experienceUrlConf = {
    serviceName: 'experience',
    serviceArgs: {
      email: getContentElement(elements.email),
    },
    commonArgs: {
      company: 'ignore',
      ...configRequest.args,
    },
    base: configRequest.base,
  };

  // GOOGLE CONFIG
  const googleUrlConf = {
    serviceName: 'google',
    serviceArgs: {
      place_id: getContentElement(elements.placeId),
    },
    commonArgs: {
      company: 'ignore',
      ...configRequest.args,
    },
    base: configRequest.base,
  };

  const { serviceArgs: { email } } = experienceUrlConf;
  const experienceUrl = email ? buildReviewsUrl(experienceUrlConf) : false;

  const { serviceArgs: { place_id: placeId } } = googleUrlConf;
  const googleUrl = placeId ? buildReviewsUrl(googleUrlConf) : false;

  // URLS
  const urls = [experienceUrl, googleUrl].filter((url) => url);

  // PROCESS GETTING REVIEWS
  fetchAll(urls)
    .then((responseData) => {
      const mergedData = responseData
        .map(({ data, error, url }) => {
          if (error) {
            // eslint-disable-next-line no-console
            console.warn(`Error: ${error} from ${url}`);
            return false;
          }

          return data;
        })
        .flat();

      if (mergedData.includes(false)) {
        sectionReviews.remove();
        return null;
      }

      return mergedData;
    })
    .then((reviews) => {
      if (reviews.length === 0) {
        // eslint-disable-next-line no-console
        console.warn('No reviews');
        sectionReviews.remove();

        return false;
      }

      renderReviews(elements, reviews, state);
      webflowRestart();
      return true;
    });

  return true;
};

initReviews();
